<template>
  <loading-overlay
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
  />
</template>
<script>
import { loadingService } from "../../services/loading.service";
import $ from "jquery";
export default {
  name: "loader",
  data() {
    return {
      isLoading: false,
      fullPage: false,
      loader: "bars",
    };
  },
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    loadingService.getloader().subscribe((loader) => {
      if (loader) {
        $(".loading").next(true);
        this.isLoading = true;
      } else {
        $(".loading").next(false);
        this.isLoading = false;
      }
    });
  },
};
</script>
